import React, { memo, useState, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Header, Footer, Form } from '@components'
import { Input, ValidationErrorMessage } from '@elements'
import { contact } from '@graphql'
import { useMutation } from '@apollo/client'
import { useAlert } from 'react-alert'
import { Motion, spring } from 'react-motion'
import { emailPattern } from '@utils'
import { Helmet } from 'react-helmet'
import styles from './index.module.scss'

interface ContactProps extends RouteComponentProps {}

const Contact: React.FC<ContactProps> = props => {
  const alert = useAlert()
  const [sendContact] = useMutation(contact.SendContact)
  // const [sendSignEmail] = useMutation(contact.SendSignEmail)
  const [startAnimation, setAnimation] = useState(false)
  const [isSubmitClicked, setSubmitClicked] = useState('Contact Us')
  const [isSignClicked, setSignClicked] = useState(false)

  const onSubmit = async (form: any) => {
    const { data } = await sendContact({ variables: form })
    if (data.sendContact.email) {
      setAnimation(false)
      setSubmitClicked('Thank You')
      setTimeout(() => {
        setAnimation(false)
        setSubmitClicked('Contact Us')
      }, 5000)
      alert.success('Success')
      setSignClicked(!isSignClicked)
    } else {
      alert.error('Something went wrong...')
    }
  }

  // const onSignSubmit = async (form: any) => {
  //   const { data } = await sendSignEmail({ variables: { email: form.sign_email } })
  //   setSignClicked(true)
  //   if (data.signEmail.email) {
  //     // alert.success('Success')
  //   } else {
  //     // alert.error('Something went wrong...')
  //   }
  // }

  const initialStyle = { opacity: 0, translateX: -100, translateX2: -172, translateY: -20, translateX3: 150 }

  useEffect(() => {
    setAnimation(true)
  }, [startAnimation, setAnimation])

  return (
    <section className={styles.contact}>
      <Helmet>
        <title>Palo Santo | Contact</title>
        <meta property="og:url" content="https://palosanto.vc/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Palo Santo" />
        <meta
          property="og:description"
          content="Leading psychedelic therapeutics investment fund dedicated to increasing the global supply of clinically effective and accessible healthcare solutions"
        />
        <meta property="og:image" content="/assets/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Header type="light" />
      <img src="../assets/images/contact.jpg" className={styles.contactBackground} />
      <Motion
        style={
          startAnimation
            ? {
                opacity: spring(1, { stiffness: 60, damping: 10 }),
                translateX: spring(0, { stiffness: 60, damping: 10 }),
                translateX2: spring(0, { stiffness: 60, damping: 10 }),
                translateY: spring(0, { stiffness: 60, damping: 10 }),
                translateX3: spring(0, { stiffness: 60, damping: 10 })
              }
            : initialStyle
        }
      >
        {interpolatedStyles => (
          <div className={styles.container} style={{ opacity: interpolatedStyles.opacity }}>
            <div className={styles.contactTitle}>
              <div className={styles.contactTitleTop}>
                <span>We’d love to hear from you.</span>
                <span>*Required Fields</span>
              </div>
              <span
                style={{
                  transform: `translateX(${interpolatedStyles.translateX}px)`
                }}
              >
                {isSubmitClicked}.
              </span>
              <div className={styles.contactLine}>
                <div
                  className={styles.contactLineInner}
                  style={{
                    transform: `translateX(${interpolatedStyles.translateX2}px)`
                  }}
                ></div>
              </div>
            </div>
            <div className={styles.contactForm}>
              <Form className={styles.contactFormWrapper} onSubmit={onSubmit} onError={() => null}>
                {useFormMethods => {
                  const requiredFieldsFilled = () => {
                    const values = useFormMethods.getValues() || {}
                    return (
                      values.first_name !== '' &&
                      values.last_name !== '' &&
                      values.email !== '' &&
                      Object.keys(useFormMethods.formState.errors).length === 0 &&
                      useFormMethods.formState.errors.constructor === Object
                    )
                  }
                  return (
                    <>
                      <a
                        href="https://www.linkedin.com/company/palosantovc/"
                        target="_blank"
                        className={styles.contactFormIn}
                      >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.6"
                            d="M10.9985 13.999V8.94851C10.9985 7.67882 10.5641 6.81317 9.47803 6.81317C9.1342 6.82018 8.80115 6.93446 8.52545 7.14001C8.24976 7.34557 8.04521 7.63213 7.9404 7.95962C7.86202 8.20658 7.82782 8.46543 7.83938 8.72427V13.999H4.83989C4.83989 13.999 4.87828 5.44044 4.83989 4.5556H7.84039V5.89297C8.10105 5.40687 8.49239 5.00326 8.97026 4.72769C9.44813 4.45212 9.99351 4.31555 10.5449 4.33338C12.5189 4.33338 14 5.68388 14 8.58487V14L10.9985 13.999ZM0.179516 13.999V4.5556H3.178V13.999H0.179516ZM1.65754 3.26773C1.43949 3.27755 1.22176 3.24228 1.01798 3.16411C0.814189 3.08594 0.628732 2.96656 0.473212 2.81343C0.317691 2.66031 0.195453 2.47674 0.114148 2.27421C0.0328433 2.07168 -0.00577885 1.85456 0.000698654 1.63642C-0.00463123 1.41502 0.0361257 1.19493 0.120383 0.990117C0.204641 0.785301 0.330559 0.600233 0.490149 0.446654C0.649738 0.293076 0.839514 0.174341 1.04743 0.097984C1.25535 0.0216269 1.47688 -0.0106852 1.69795 0.0030979C1.9176 -0.00870716 2.13732 0.0251214 2.34324 0.10245C2.54916 0.179779 2.73682 0.298931 2.89439 0.452391C3.05196 0.605851 3.17601 0.79029 3.25873 0.994076C3.34145 1.19786 3.38104 1.41658 3.375 1.63642C3.3802 1.8577 3.33933 2.07764 3.255 2.28228C3.17067 2.48693 3.04472 2.67181 2.88512 2.8252C2.72553 2.97859 2.53579 3.09714 2.32794 3.17332C2.1201 3.2495 1.89868 3.28165 1.67775 3.26773H1.65754Z"
                            fill="#F8EDDC"
                          />
                        </svg>
                      </a>
                      <div className={styles.contactMainInfo}>
                        <div className={styles.contactInput}>
                          <Input
                            width={320}
                            name="first_name"
                            label="FIRST NAME*"
                            validation={{ required: { value: true, message: 'First name is required' } }}
                            placeholder="Enter your first name"
                          />
                        </div>
                        <div className={styles.contactInput}>
                          <Input
                            width={320}
                            name="last_name"
                            label="LAST NAME*"
                            validation={{ required: { value: true, message: 'Last name is required' } }}
                            placeholder="Enter your last name"
                          />
                        </div>
                        <div className={styles.contactInput}>
                          <Input
                            width={320}
                            name="email"
                            label="EMAIL*"
                            validation={{
                              required: { value: true, message: 'Email is required' },
                              pattern: { value: emailPattern, message: 'We don`t see email here' }
                            }}
                            placeholder="Enter your email"
                          />
                        </div>
                      </div>
                      <div className={styles.contactMessage}>
                        <Input name="message" width={768} label="MESSAGE" placeholder="Enter your message" />
                        <button
                          type="submit"
                          className={
                            isSubmitClicked !== 'Contact Us'
                              ? styles.contactSubmitSubmitted
                              : requiredFieldsFilled()
                              ? styles.contactSubmitFilled
                              : styles.contactSubmit
                          }
                        >
                          {isSubmitClicked !== 'Contact Us' ? 'Submitted!' : 'Submit'}
                          <svg
                            width="17"
                            height="15"
                            viewBox="0 0 17 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.4858 7.48528L9.10711 14.8639L8.12352 13.8804L13.8263 8.17754L0.206757 8.17683V6.79373L13.8256 6.79373L8.12282 1.09091L9.10711 0.106622L16.4858 7.48528Z"
                              fill={requiredFieldsFilled() ? '#140b26' : '#FFC469'}
                            />
                          </svg>
                        </button>
                      </div>
                      <div className={styles.contactSubscribe} onClick={() => setSignClicked(!isSignClicked)}>
                        <div
                          className={
                            isSignClicked ? styles.contactSubscribeCheckboxActive : styles.contactSubscribeCheckbox
                          }
                        >
                          {isSignClicked && (
                            <div className={styles.contactSubscribeCheckboxMark}>
                              <svg
                                width="42"
                                height="47"
                                viewBox="0 0 42 47"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M4 14.5L16.25 37.5L37.5 3" stroke="#F7EFDF" strokeWidth="9" />
                              </svg>
                            </div>
                          )}
                        </div>
                        Sign Up for Palo Santo Updates & Latest Psychedelic News
                      </div>
                    </>
                  )
                }}
              </Form>
              <div className={styles.contactFooter}>
                <div className={styles.contactFooterEmail}>
                  EMAIL US
                  <a target="_blank" href="mailto:info@palosanto.vc?subject=Palo%20Santo%20Inquiry">
                    info@palosanto.vc
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Motion>
      <Footer type="half" />
    </section>
  )
}

export default memo(Contact)
